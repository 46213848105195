import { ICountry } from "src/app/api/country/country.interface";

import { createAction, props } from "@ngrx/store";

import { User } from "../user/user";

export const getCountry = createAction("[App] Get country");
export const getCountrySuccess = createAction(
  "[App] Get country success",
  props<{ country: ICountry }>()
);
export const getMe = createAction(
  "[App] Get me",
  props<{ goHome?: boolean }>()
);
export const getMeSuccess = createAction(
  "[App] Get me success",
  props<{ user: User }>()
);
