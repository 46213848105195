import { of } from "rxjs";
import { CommonResponseBody } from "src/app/common/types/responses/response";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiPot, CreatePotDto, RequestPayoutPotDto } from "./pot";

@Injectable({
  providedIn: "root",
})
export class PotApiService {
  readonly path = "/pots";

  constructor(private readonly http: HttpClient) {}

  getById(id: string) {
    return this.http.get<ApiPot>(`${this.path}/${id}`);
  }

  getSlug(slug: string) {
    return this.http.get<ApiPot>(`${this.path}/s/${slug}`);
  }

  create(dto: CreatePotDto) {
    return this.http.post<string>(this.path, dto);
  }

  getMyPots() {
    const url = `${this.path}/me/all`;
    return this.http.get<ApiPot[]>(url);
  }

  getMyLastPot() {
    const url = `${this.path}/me/last`;
    return this.http.get<ApiPot>(url);
  }

  getMyPayouts() {
    const url = `${this.path}/me/payouts`;
    return this.http.get<ApiPot[]>(url);
  }

  getAmountCollected(potId: string) {
    if (!potId) {
      return of({ amount: 0 });
    }
    const url = `${this.path}/amount-collected/${potId}`;
    return this.http.get<{ amount: number }>(url);
  }

  getAmountCollectedPending(potId: string) {
    const url = `${this.path}/amount-collected-pending/${potId}`;
    return this.http.get<{ amount: number }>(url);
  }

  requestPayout(body: RequestPayoutPotDto) {
    const url = `${this.path}/request-payout`;
    return this.http.post(url, body);
  }

  deleteById(id: string) {
    return this.http.delete<CommonResponseBody>(`${this.path}/${id}`);
  }
}
