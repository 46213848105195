import { EMPTY } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { DonationApiService } from "src/app/api/donation/donation-api.service";
import { PotApiService } from "src/app/api/pot/pot-api.service";

import { inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
} from "@angular/router";

@Injectable({ providedIn: "root" })
export class PotResolver {
  readonly router = inject(Router);

  constructor(
    private service: PotApiService,
    private readonly donationApi: DonationApiService
  ) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const slug = route.paramMap.get("slug");
    return this.service.getSlug(slug).pipe(
      switchMap((pot) => {
        return this.donationApi
          .getDonations(pot._id)
          .pipe(map((donations) => ({ ...pot, donations })));
      }),
      switchMap((pot) => {
        return this.service
          .getAmountCollected(pot._id)
          .pipe(map((r) => ({ ...pot, amountCollected: r.amount })));
      }),
      catchError((err) => {
        console.error(err);

        this.router.navigate(["/pot-not-found"]);
        return EMPTY;
      })
    );
  }
}
