import { Subject } from "rxjs";
import { AuthApiService } from "src/app/api/auth/auth-api.service";
import { ApiUser } from "src/app/api/user/user";
import { UserApiService } from "src/app/api/user/user-api.service";
import { getMe } from "src/app/core/state/app.actions";

import { inject, Injectable, signal } from "@angular/core";
import {
  Auth,
  authState,
  GoogleAuthProvider,
  idToken,
  signInWithCredential,
  signInWithPopup,
  user,
  UserCredential,
} from "@angular/fire/auth";
import { Router } from "@angular/router";
import { Toast } from "@capacitor/toast";
import { AlertController } from "@ionic/angular/standalone";
import { Store } from "@ngrx/store";

import { AlertNo, AlertOK } from "../constants";
import { ExceptionMessages } from "../types/responses/exception-messages";

export const ACCESS_TOKEN_KEY = "access_token";
@Injectable({
  providedIn: "root",
})
export class AuthService {
  private auth = inject(Auth);
  user$ = user(this.auth);
  authState$ = authState(this.auth);
  idToken$ = idToken(this.auth);
  idToken: string | undefined;

  readonly router = inject(Router);
  readonly store = inject(Store);
  readonly authApiService = inject(AuthApiService);
  provider = new GoogleAuthProvider();
  public alertController = inject(AlertController);
  readonly errorMessage$ = new Subject<string>();
  readonly userApiService: UserApiService = inject(UserApiService);
  readonly showAuthSpinner = signal<boolean>(null);

  constructor() {
    this.auth.useDeviceLanguage();
    this.provider.addScope("https://www.googleapis.com/auth/contacts.readonly");
  }

  async logout() {
    await this.auth.signOut();
  }

  async googleAuth(redirectUrl?: string) {
    let userCredential: UserCredential;
    try {
      const result = await signInWithPopup(this.auth, this.provider);
      const credential = GoogleAuthProvider.credentialFromResult(result);
      if (!result) {
        this.auth.signOut();
        location.reload();
      }
      const _credential = GoogleAuthProvider.credential(credential?.idToken);
      try {
        userCredential = await signInWithCredential(this.auth, _credential);
        if (!userCredential?.user) {
          this.errorMessage$.next("Echec de la connexion");
        } else {
          const idToken = await userCredential.user.getIdToken();
          if (!idToken) {
            this.errorMessage$.next("Echec de la connexion");
          }
          await this.googleLogin(idToken, userCredential.user, redirectUrl);
        }
      } catch (error) {
        if (error?.code === "auth/invalid-credential") {
          this.errorMessage$.next("Identitifants incorrects");
        }
      }
    } catch (error) {
      this.auth.signOut();
      location.reload();
    }
  }

  // async googleAuth(redirectUrl?: string) {
  //   let userCredential: UserCredential;
  //   try {
  //     let googleUser = await FirebaseAuthentication.signInWithGoogle({
  //       mode: "redirect",
  //     });
  //     if (!googleUser) {
  //       this.auth.signOut();
  //       location.reload();
  //     }
  //     const _credential = GoogleAuthProvider.credential(
  //       googleUser?.credential?.idToken
  //     );
  //     try {
  //       userCredential = await signInWithCredential(this.auth, _credential);
  //       if (!userCredential?.user) {
  //         this.errorMessage$.next("Echec de la connexion");
  //       } else {
  //         const idToken = await userCredential.user.getIdToken();
  //         if (!idToken) {
  //           this.errorMessage$.next("Echec de la connexion");
  //         }
  //         await this.googleLogin(idToken, userCredential.user, redirectUrl);
  //       }
  //     } catch (error) {
  //       if (error?.code === "auth/invalid-credential") {
  //         this.errorMessage$.next("Identitifants incorrects");
  //       }
  //     }
  //   } catch (error) {
  //     this.auth.signOut();
  //     location.reload();
  //   }
  // }

  async googleLogin(
    idToken: string,
    credentialUser: { email: string },
    redirectUrl?: string
  ) {
    this.showAuthSpinner.set(true);

    this.authApiService
      .googleLogin({
        idToken,
      })
      .subscribe({
        next: (user) => {
          this.idToken = idToken;
          this.showAuthSpinner.set(false);

          this.showHelloToast(user);

          this.store.dispatch(getMe({}));

          if (redirectUrl) {
            this.router.navigateByUrl(redirectUrl);
          }
        },
        error: (error) => {
          this.showAuthSpinner.set(false);
          if (error?.message === ExceptionMessages.USER_NOT_FOUND) {
            this.alertController
              .create({
                cssClass: "custom-alert",
                header: "S'inscrire",
                message: `Le compte ${credentialUser?.email} n'existe pas. Voulez-vous vous inscrire ? `,
                buttons: [
                  {
                    ...AlertNo,
                    handler: (_) => {},
                  },
                  {
                    ...AlertOK,
                    handler: () => {
                      this.googleRegister(idToken, credentialUser, redirectUrl);
                    },
                  },
                ],
              })
              .then((alert) => {
                alert.present();
              });
          }

          if (error?.message === ExceptionMessages.LOGIN_FORBIDDEN) {
            this.errorMessage$.next("Vous avez désactivé votre comtpe");
          }

          if (error?.message === ExceptionMessages.OPERATION_ERROR) {
            this.errorMessage$.next("Echec. Veuillez réessayer plus tard.");
          }
        },
      });
  }

  async googleRegister(
    idToken: string,
    credentialUser: { email: string },
    redirectUrl?: string
  ) {
    this.showAuthSpinner.set(true);
    await this.authApiService
      .googleRegister({
        idToken,
      })
      .subscribe({
        next: (user) => {
          this.googleLogin(idToken, credentialUser, redirectUrl);
        },
        error: (error) => {
          this.showAuthSpinner.set(false);

          if (error?.message === ExceptionMessages.EMAIL_ALREADY_REGISTRED) {
            this.alertController
              .create({
                cssClass: "custom-alert",
                header: "Se connecter",
                message: `Le compte ${credentialUser?.email} existe déjà. Voulez-vous vous connecter ? `,
                buttons: [
                  {
                    ...AlertNo,
                    handler: (_) => {},
                  },
                  {
                    ...AlertOK,
                    handler: () => {
                      this.googleLogin(idToken, credentialUser, redirectUrl);
                    },
                  },
                ],
              })
              .then((alert) => {
                alert.present();
              });
          }

          if (error?.message === ExceptionMessages.OPERATION_ERROR) {
            this.errorMessage$.next("Echec. Veuillez réessayer plus tard.");
          }
        },
      });
  }

  async showHelloToast(user: ApiUser) {
    if (!user) return;
    await Toast.show({
      text: `Hello ${user?.personalInfo?.firstname ?? ""} !`,
    });
  }
}
