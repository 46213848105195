@if(showUploadSpinner()) {
<ion-spinner class="tw-ml-2" name="crescent"></ion-spinner>
} @else { @if(user$ | async; as user) {
<div
  class="avatar"
  (click)="onProfileClick()"
  [ngStyle]="{ 
        'background-image': 'url(' + displayedPhoto() + ')',
        'width': size,
        'height': size,
        }"
></div>
} }
