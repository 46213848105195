import { DonationApiService } from "src/app/api/donation/donation-api.service";

import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable({ providedIn: "root" })
export class DonationResolver {
  constructor(private readonly service: DonationApiService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const donationId = route.paramMap.get("donationId");
    return this.service.getById(donationId);
  }
}
