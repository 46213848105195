import { UserApiService } from "src/app/api/user/user-api.service";
import { selectUser } from "src/app/core/state/app.selectors";

import { AsyncPipe, NgStyle } from "@angular/common";
import {
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from "@angular/core";
import { toSignal } from "@angular/core/rxjs-interop";
import { IonSpinner } from "@ionic/angular/standalone";
import { Store } from "@ngrx/store";

import { MessageService } from "../../services";

@Component({
  selector: "user-photo",
  templateUrl: "./user-photo.component.html",
  styleUrls: ["./user-photo.component.scss"],
  standalone: true,
  imports: [NgStyle, AsyncPipe, IonSpinner],
})
export class UserPhotoComponent implements OnInit {
  @Input() size: string = "6.25rem";
  image = input("assets/user.svg");
  @Output() profileClick = new EventEmitter();
  showUploadSpinner: WritableSignal<boolean> = signal(null);
  readonly user$ = this.store.select(selectUser);
  readonly userApiSercice = inject(UserApiService);
  readonly messageService = inject(MessageService);

  userSignal = toSignal(this.user$);
  displayedPhoto = computed(() => {
    return this.userSignal()?.photo || this.image();
  });

  constructor(readonly store: Store) {}

  ngOnInit() {}

  onProfileClick() {
    this.profileClick.emit();
  }
}
