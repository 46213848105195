const server = "https://doon-api-f01d22588e89.herokuapp.com";
export const environment = {
  production: false,
  country: "67a951cc4ef804ecaacdf8da",
  locale: "fr-FR",
  currency: "XOF",
  server,
  getIpAdressUrl: "https://ipinfo.io/json",
  cinetpay: {
    cpK: "696297544611a72a3237119.53867449",
    si: 276377,
    cmode: "PRODUCTION",
  },
  firebase: {
    apiKey: "AIzaSyB37k34-OwdkvwwrM0YnjyyWBnoT3ws4Q4",
    authDomain: "doon-7ee6e.firebaseapp.com",
    projectId: "doon-7ee6e",
    storageBucket: "doon-7ee6e.firebasestorage.app",
    messagingSenderId: "778838853689",
    appId: "1:778838853689:web:5387daf9d6acb3bee4a5e8",
    measurementId: "G-S32E17182B",
  },
};
