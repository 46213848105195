import { createFeatureSelector, createSelector } from "@ngrx/store";

import { AppState } from "./app.state";

export const selectApp = createFeatureSelector<AppState>("app");
export const selectCountry = createSelector(selectApp, (state) => ({
  ...state.country,
  payoutServices: state.country?.paymentServices.filter((ps) => ps?.canPayout),
}));

export const selectNationalities = createSelector(
  selectApp,
  (state) => state.country?.nationalities
);

export const selectUser = createSelector(selectApp, (state) => state.user);

export const selectAllowedCountry = createSelector(
  selectNationalities,
  selectCountry,
  (nationalities, country) => nationalities.find((c) => c.code === country.code)
);
