import { addIcons } from "ionicons";
import { menuOutline, phonePortraitOutline } from "ionicons/icons";
import { map } from "rxjs";
import { UserPhotoComponent } from "src/app/common/components/user-photo/user-photo.component";
import { AuthService } from "src/app/common/services";
import { selectCountry, selectUser } from "src/app/core/state/app.selectors";

import { AsyncPipe, CommonModule } from "@angular/common";
import {
  AfterViewInit,
  Component,
  HostListener,
  inject,
  OnInit,
} from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterLink, RouterOutlet } from "@angular/router";
import {
  AlertOptions,
  IonButton,
  IonContent,
  IonFooter,
  IonHeader,
  IonIcon,
  IonRouterOutlet,
  IonSelect,
  IonText,
  IonTitle,
  IonToolbar,
} from "@ionic/angular/standalone";
import { Store } from "@ngrx/store";

@Component({
  selector: "app-landing",
  templateUrl: "./landing.page.html",
  styleUrls: ["./landing.page.scss"],
  standalone: true,
  imports: [
    IonIcon,
    IonText,
    IonButton,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    CommonModule,
    FormsModule,
    RouterLink,
    IonRouterOutlet,
    IonFooter,
    ReactiveFormsModule,
    IonSelect,
    RouterOutlet,
    UserPhotoComponent,
    AsyncPipe,
  ],
})
export class LandingPage implements OnInit, AfterViewInit {
  navbarOpen = false;
  date = new Date().getFullYear();
  showMenu = false;

  readonly endAt = new Date(new Date().setDate(new Date().getDate() + 30));
  readonly reasonControl = new FormControl();
  selectOptions: AlertOptions = {
    header: "Selectionnez un motif",
    cssClass: "custom-alert",
  };

  paymentServices$ = this.store
    .select(selectCountry)
    .pipe(map((s) => s.paymentServices));

  readonly authService = inject(AuthService);

  readonly user$ = this.store.select(selectUser);

  androidAppUrl$ = this.store
    .select(selectCountry)
    .pipe(map((s) => s.androidAppUrl));
  iosAppUrl$ = this.store.select(selectCountry).pipe(map((s) => s.iosAppUrl));

  constructor(private readonly store: Store) {
    addIcons({ menuOutline });
  }
  ngAfterViewInit(): void {
    this.showMenu = window.innerWidth >= 640;
  }

  ngOnInit(): void {
    addIcons({ phonePortraitOutline, menuOutline });
  }

  setNavbarOpen() {
    this.navbarOpen = !this.navbarOpen;
  }

  @HostListener("window:resize", ["$event"])
  onResize(event: any) {
    this.showMenu = window.innerWidth >= 640;
  }

  onProfileClick() {}
}
