import { Component, inject, OnInit, ViewChild } from "@angular/core";
import { Auth, idToken } from "@angular/fire/auth";
import { Router } from "@angular/router";
import { AlertController } from "@ionic/angular";
import { IonApp, IonRouterOutlet } from "@ionic/angular/standalone";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";

import { AuthService } from "./common/services";

@Component({
  selector: "app-root",
  standalone: true,
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  imports: [IonRouterOutlet, IonApp],
  providers: [],
})
export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;

  private auth = inject(Auth);
  idToken$ = idToken(this.auth);
  authService = inject(AuthService);
  readonly store = inject(Store);

  constructor(
    public alertController: AlertController,
    public translateService: TranslateService,
    public readonly router: Router
  ) {
    this.translateService.setDefaultLang("en");
  }

  ngOnInit() {}
}
