import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiUser } from "../user/user";
import { GoogleLoginDto, GoogleRegisterDto } from "./auth";

@Injectable({
  providedIn: "root",
})
export class AuthApiService {
  readonly path = "/auth";

  constructor(private readonly http: HttpClient) {}

  googleLogin(body: GoogleLoginDto) {
    return this.http.post<ApiUser>(`${this.path}/google-login`, body);
  }
  googleRegister(body: GoogleRegisterDto) {
    return this.http.post<ApiUser>(`${this.path}/google-register`, body);
  }
}
