import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ApiDonation, CreateDonationDto } from "./donation";

@Injectable({
  providedIn: "root",
})
export class DonationApiService {
  readonly path = "/donations";

  constructor(private readonly http: HttpClient) {}

  getById(id: string, populate: boolean = true) {
    return this.http.get<ApiDonation>(
      `${this.path}/${id}?populate=${populate}`
    );
  }

  create(dto: CreateDonationDto) {
    return this.http.post<ApiDonation>(this.path, dto);
  }

  getDonations(potId: string) {
    const url = `${this.path}/pot/${potId}`;
    return this.http.get<ApiDonation[]>(url);
  }
}
