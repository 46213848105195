import { Routes } from "@angular/router";

import { AuthGuard } from "./common/services/auth.guard";
import { DonationResolver } from "./common/services/donation-resolver.service";
import { PotResolver } from "./common/services/pot-resolver.service";
import { LandingPage } from "./pages/landing/landing.page";
import { VerifyEmailGuard } from "./pages/verify-email/verify-email.guard";

export const routes: Routes = [
  {
    path: "",
    component: LandingPage,
    children: [
      {
        path: "",
        loadComponent: () =>
          import("./pages/home/home.page").then((m) => m.HomePage),
      },
      {
        path: "pricing",
        loadComponent: () =>
          import("./pages/pricing/pricing.page").then((m) => m.PricingPage),
      },
      {
        path: "terms",
        loadComponent: () =>
          import("./pages/terms/terms.page").then((m) => m.TermsPage),
      },
      {
        path: "help",
        loadComponent: () =>
          import("./pages/help-page/help-page.page").then(
            (m) => m.HelpPagePage
          ),
      },
      {
        path: "pot-not-found",
        loadComponent: () =>
          import("./pages/pot-not-found/pot-not-found.page").then(
            (m) => m.PotNotFoundPage
          ),
      },
      {
        path: "about",
        loadComponent: () =>
          import("./pages/about/about.page").then((m) => m.AboutPage),
      },
      {
        path: "terms",
        loadComponent: () =>
          import("./pages/terms/terms.page").then((m) => m.TermsPage),
      },
      {
        path: "verify-email",
        canActivate: [AuthGuard],
        loadComponent: () =>
          import("./pages/verify-email/verify-email.page").then(
            (m) => m.VerifyEmailPage
          ),
      },
      {
        path: "pot",
        children: [
          {
            path: ":slug",
            loadComponent: () =>
              import("./pages/pot/pot.page").then((m) => m.PotPage),
          },
          {
            path: "participate/:slug",
            canActivate: [AuthGuard, VerifyEmailGuard],
            loadComponent: () =>
              import("./pages/participate/participate.page").then(
                (m) => m.ParticipatePage
              ),
            resolve: {
              pot: PotResolver,
            },
          },
          {
            path: "donation-status/:donationId",
            loadComponent: () =>
              import("./pages/donation-status/donation-status.page").then(
                (m) => m.DonationStatusPage
              ),
            resolve: {
              donation: DonationResolver,
            },
          },
        ],
      },
    ],
  },
];
