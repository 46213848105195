import { NgxPermissionsModule } from "ngx-permissions";
import {
  catchError,
  combineLatest,
  from,
  Observable,
  of,
  switchMap,
  take,
  tap,
} from "rxjs";
import { environment } from "src/environments/environment";

import { CurrencyPipe, registerLocaleData } from "@angular/common";
import {
  HttpClient,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from "@angular/common/http";
import localeFr from "@angular/common/locales/fr";
import {
  APP_INITIALIZER,
  ApplicationConfig,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  importProvidersFrom,
  InjectionToken,
  LOCALE_ID,
} from "@angular/core";
import { getApp, initializeApp, provideFirebaseApp } from "@angular/fire/app";
import {
  getAuth,
  indexedDBLocalPersistence,
  initializeAuth,
  provideAuth,
} from "@angular/fire/auth";
import {
  PreloadAllModules,
  provideRouter,
  Router,
  RouteReuseStrategy,
  withInMemoryScrolling,
  withPreloading,
  withRouterConfig,
} from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { IonicRouteStrategy } from "@ionic/angular";
import { provideIonicAngular } from "@ionic/angular/standalone";
import { provideEffects } from "@ngrx/effects";
import { provideStore, Store } from "@ngrx/store";
import { provideStoreDevtools } from "@ngrx/store-devtools";
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import * as SentryAngular from "@sentry/angular";
import * as Sentry from "@sentry/capacitor";

import { ApiInterceptor } from "./api/api-interceptor.";
import { CountryApiService } from "./api/country/country-api.service";
import { routes } from "./app.routes";
import { LocalCurrencyPipe } from "./common/pipes/local-currency.pipe";
import { AuthService } from "./common/services";
import { getCountrySuccess, getMe } from "./core/state/app.actions";
import { appReducer } from "./core/state/app.state";
import { getMeEffects } from "./core/state/get-me.effects";

registerLocaleData(localeFr);
Sentry.init(
  {
    dsn: "https://68a2b7f16f012a2d87643be7e016c311@o4508299490885632.ingest.us.sentry.io/4508299492786176",

    // Set your release version, such as "getsentry@1.0.0"
    release: "doon@2.0.0",
    // Set your dist version, such as "1"
    dist: "1",
    integrations: [
      // Registers and configures the Tracing integration,
      // which automatically instruments your application to monitor its
      // performance, including custom Angular routing instrumentation
      Sentry.browserTracingIntegration(),
      // Registers the Replay integration,
      // which automatically captures Session Replays
      Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  },
  // Forward the init method from @sentry/angular
  SentryAngular.init
);
export const X_COUNTRY = new InjectionToken<string>("X_COUNTRY");
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
export function initializeAppFactory(
  countryApiService: CountryApiService,
  store: Store,
  translateService: TranslateService,
  authService: AuthService,
  country: string
) {
  return (): Observable<any> => {
    return combineLatest([
      countryApiService.getById(country),
      authService.authState$.pipe(
        switchMap((authUser) =>
          from(authUser?.getIdToken() || Promise.resolve(null))
        )
      ),
    ]).pipe(
      take(1),
      tap(([country, token]) => {
        if (country) {
          translateService.use(country.languages[0]);
          store.dispatch(getCountrySuccess({ country }));
        }

        if (token) {
          authService.idToken = token;
          store.dispatch(getMe({}));
        }
      }),
      switchMap(() => of(true)),
      catchError((err) => {
        return of(true);
      })
    );
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: ErrorHandler,
      useValue: SentryAngular.createErrorHandler(),
    },
    {
      provide: SentryAngular.TraceService,
      deps: [Router],
    },
    {
      provide: X_COUNTRY,
      useValue: environment.country,
    },
    {
      provide: LOCALE_ID,
      useValue: environment.locale,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: environment.currency,
    },
    CurrencyPipe,
    LocalCurrencyPipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      multi: true,
      deps: [
        CountryApiService,
        Store,
        TranslateService,
        AuthService,
        X_COUNTRY,
      ],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [SentryAngular.TraceService],
      multi: true,
    },
    provideHttpClient(
      withInterceptorsFromDi(),
      withInterceptors([ApiInterceptor])
    ),
    provideRouter(
      routes,
      withPreloading(PreloadAllModules), // https://web.dev/articles/route-preloading-in-angular
      withRouterConfig({
        onSameUrlNavigation: "reload",
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: "top",
      })
    ),
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    provideIonicAngular(),
    provideStore({ app: appReducer }),
    provideEffects(getMeEffects),
    provideStoreDevtools({
      maxAge: 25,
      logOnly: !environment.production,
      autoPause: true,
    }),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: [indexedDBLocalPersistence],
        });
      } else {
        return getAuth();
      }
    }),
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      NgxPermissionsModule.forRoot()
    ),
  ],
};
