import { Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";

import { ICountry } from "./country.interface";

@Injectable({
  providedIn: "root",
})
export class CountryApiService {
  readonly path = "/countries";

  constructor(private readonly http: HttpClient) {}

  getCountries() {
    return this.http.get(this.path);
  }

  getById(id: string): Observable<ICountry> {
    return this.http.get<ICountry>(`/countries/${id}`);
  }
}
