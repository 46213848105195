import { catchError, EMPTY, map, Observable } from "rxjs";
import { UserApiService } from "src/app/api/user/user-api.service";
import { AuthService } from "src/app/common/services";

import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class VerifyEmailGuard implements CanActivate {
  constructor(
    private readonly userApiService: UserApiService,
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree
    | MaybeAsync<GuardResult> {
    return this.userApiService.getMe().pipe(
      map((user) => {
        if (
          !user?.personalInfo?.email?.verified &&
          !this.router.url.includes("verify-email")
        ) {
          this.router.navigate(["/verify-email"], {
            queryParams: { returnUrl: state.url },
            skipLocationChange: true,
          });
          return false;
        }

        return true;
      }),
      catchError((err) => {
        this.router.navigate(["/"]);
        return EMPTY;
      })
    );
  }
}
