import { NgxPermissionsService } from "ngx-permissions";
import {
  catchError,
  EMPTY,
  from,
  map,
  Observable,
  switchMap,
  tap,
  throwError,
} from "rxjs";
import { UserApiService } from "src/app/api/user/user-api.service";
import { getMeSuccess } from "src/app/core/state/app.actions";
import { selectUser } from "src/app/core/state/app.selectors";

import { inject, Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  GuardResult,
  MaybeAsync,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Store } from "@ngrx/store";

import { ExceptionMessages } from "../types/responses/exception-messages";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {
  user$ = this.store.select(selectUser);
  userApiService = inject(UserApiService);
  permissionsService = inject(NgxPermissionsService);
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly store: Store
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree
    | MaybeAsync<GuardResult> {
    return this.authService.authState$.pipe(
      switchMap((authUser) => {
        if (!authUser) {
          return throwError(() => new Error(ExceptionMessages.LOGIN_FORBIDDEN));
        }
        return from(authUser.getIdToken()).pipe(
          tap((token) => (this.authService.idToken = token)),
          switchMap(() => {
            return this.userApiService.getMe().pipe(
              map((user) => {
                if (!user) {
                  throwError(
                    () => new Error(ExceptionMessages.LOGIN_FORBIDDEN)
                  );
                } else {
                  this.permissionsService.loadPermissions(user.permissions);
                  this.store.dispatch(getMeSuccess({ user: user }));
                  return !!user;
                }
              }),
              catchError((err) => {
                return throwError(
                  () => new Error(ExceptionMessages.LOGIN_FORBIDDEN)
                );
              })
            );
          })
        );
      }),
      catchError((err) => {
        this.router.navigate(["/"]);
        return EMPTY;
      })
    );
  }
}
